<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/driver-list" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>
        <span v-if="$route.name === 'editDriver'"
          >Жолооч Засах
          <div class="editField">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="panel">
                  <el-form
                    v-loading="loading"
                    label-position="top"
                    size="mini"
                    :rules="rules"
                    :model="driveDetail"
                    ref="driveCreate"
                  >
                    <el-row :gutter="0">
                      <el-col :span="12" :offset="6">
                        <el-form-item prop="is_hr_verified">
                          <p>Хүний нөөц зөвшөөрсөн эсэх?</p>
                          <el-switch
                            v-model="driveDetail.is_hr_verified"
                            border
                            active-color="#13ce66"
                            :active-value="true"
                            :inactive-value="false"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item label="Регистрийн дугаар">
                          <el-input
                            placeholder="РД дугаар"
                            v-model="driveDetail.register_number"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Гэрээний дугаар">
                          <el-input
                            placeholder="гэрээний дугаар"
                            v-model="driveDetail.contract_number"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Жолоочийн овог" prop="last_name">
                          <el-input v-model="driveDetail.last_name"></el-input>
                        </el-form-item>
                        <el-form-item label="Жолоочийн нэр" prop="first_name">
                          <el-input v-model="driveDetail.first_name"></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Жолоочийн төрсөн огноо"
                          prop="birth_date"
                        >
                          <el-date-picker
                            v-model="driveDetail.birth_date"
                            type="date"
                            placeholder="огноо оруулах"
                            format="yyyy/MM/dd"
                            value-format="yyyy-MM-dd"
                          >
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="Жолоочийн нэвтрэх нэр">
                          <el-input
                            placeholder="нэр"
                            v-model="driveDetail.full_name"
                            disabled
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Жолоочийн утас">
                          <el-input
                            placeholder="Утас"
                            v-model="driveDetail.phone"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Мэйл хаяг">
                          <el-input
                            placeholder="мэйл хаяг"
                            v-model="driveDetail.email"
                            disabled
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Банкны нэр">
                          <el-select
                            v-model="driveDetail.bank_code"
                            placeholder="Банкны нэр"
                          >
                            <el-option
                              v-for="(item, index) in banks"
                              :key="index"
                              :label="item.bank_name_mon"
                              :value="item.bank_code"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="Дансны нэр">
                          <el-input
                            placeholder="Карт нь дээрх нэр"
                            v-model="driveDetail.bank_account_name"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Дансны дугаар">
                          <el-input
                            placeholder="Дансны дугаар"
                            v-model="driveDetail.bank_account_number"
                          ></el-input> </el-form-item
                        ><el-form-item label="Ажил эрхлэлт">
                          <el-select
                            v-model="driveCreate.work_status"
                            placeholder="Ажил эрхлэлт"
                          >
                            <el-option
                              label="Сурагч"
                              value="student"
                            ></el-option>
                            <el-option
                              label="Оюутан"
                              value="uni_student"
                            ></el-option>
                            <el-option
                              label="Давхар ажил эрхлэдэг"
                              value="side_job"
                            ></el-option>
                            <el-option
                              label="Ток Ток-д бүтэн цагаар ажиллана"
                              value="full_time_toktok"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="Тээврийн хэрэгслийн төрөл">
                          <el-select
                            v-model="driveCreate.vehicle_type"
                            placeholder="Тээврийн хэрэгслийн төрөл"
                          >
                            <el-option label="Машин" value="car"></el-option>
                            <el-option label="Мопед" value="moped"></el-option>
                            <el-option
                              label="Дугуй"
                              value="bicycle"
                            ></el-option>
                            <el-option
                              label="Алхагч"
                              value="walker"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="Жолоочийн баг сонгоно уу">
                          <el-select
                            v-model="selectedTeam"
                            @change="calculateTeam"
                            placeholder="Жолоочийн багийг сонго"
                          >
                            <el-option
                              v-for="(item, index) in allDrivers"
                              :key="index"
                              :label="item.group_name"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item prop="is_company_employee">
                          <p>Үндсэн ажилтан эсэх?</p>
                          <el-switch
                            v-model="driveDetail.is_company_employee"
                            border
                            active-color="#13ce66"
                            :active-value="true"
                            :inactive-value="false"
                            name="slkdafj"
                          ></el-switch>
                        </el-form-item>
                        <p>Жолоочийн эрх</p>
                        <el-radio-group
                          v-model="driveDetail.is_disabled"
                          class="mb10"
                        >
                          <el-radio :label="true">Идэвхгүй</el-radio>
                          <el-radio :label="false">Идэвхтэй</el-radio>
                        </el-radio-group>
                        <div style="margintop: 20px">
                          <el-row :gutter="20">
                            <el-col :span="12">
                              <router-link to="/driver-list">
                                <el-button size="medium" class="full-width"
                                  >Буцах</el-button
                                >
                              </router-link>
                            </el-col>
                            <el-col :span="12">
                              <a>
                                <el-button
                                  size="medium"
                                  type="success"
                                  @click="save"
                                  >Хадгалах</el-button
                                >
                              </a>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="12" v-loading="loadingBadge">
                <div class="panel">
                  <el-row type="flex" justify="end">
                    <el-button
                      size="mini"
                      type="success"
                      @click="visibleBadgeDialog = true"
                      >Badge Нэмэх</el-button
                    >
                  </el-row>
                  <el-table
                    :data="driverBadges"
                    v-if="driverBadges.length > 0 && badgeTypes.length > 0"
                  >
                    <el-table-column label="Badge">
                      <template slot-scope="scope">
                        <el-image
                          style="height: 50px; width: 50px"
                          :src="
                            badgeTypes.find(
                              el => el.key === scope.row.badge_key
                            ).img_url || ''
                          "
                        ></el-image>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Огноо"
                      prop="owned_date"
                    ></el-table-column>
                    <el-table-column align="right">
                      <template slot-scope="scope">
                        <el-button
                          type="primary"
                          icon="el-icon-edit"
                          circle
                          @click="editBadge(scope.row, 'edit')"
                        ></el-button>
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="deleteBadge(scope.row.id)"
                        ></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
        <span v-else-if="$route.name === 'addDriver'">
          Жолооч нэмэх
          <div class="editField">
            <el-row :gutter="12">
              <el-col :span="12" :offset="6">
                <div class="panel">
                  <el-form
                    label-position="top"
                    size="mini"
                    :rules="rules"
                    :model="driveCreate"
                    ref="driveCreate"
                  >
                    <el-row :gutter="0">
                      <el-col :span="12" :offset="6">
                        <el-form-item prop="is_hr_verified">
                          <p>Хүний нөөц зөвшөөрсөн эсэх?</p>
                          <el-switch
                            v-model="driveDetail.is_hr_verified"
                            border
                            active-color="#13ce66"
                            :active-value="true"
                            :inactive-value="false"
                          ></el-switch>
                        </el-form-item>
                        <el-form-item
                          label="Жолоочийн регистрийн дугаар"
                          prop="register_number"
                        >
                          <el-input
                            v-model="driveCreate.register_number"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Жолоочийн гэрээний дугаар"
                          prop="contract_number"
                        >
                          <el-input
                            v-model="driveCreate.contract_number"
                          ></el-input>
                        </el-form-item>
                        <!-- <el-form-item
                          label="Жолоочийн нэвтрэх нэр"
                          prop="full_name"
                        >
                          <el-input v-model="driveCreate.full_name"></el-input>
                        </el-form-item> -->
                        <el-form-item label="Жолоочийн овог" prop="last_name">
                          <el-input v-model="driveCreate.last_name"></el-input>
                        </el-form-item>
                        <el-form-item label="Жолоочийн нэр" prop="first_name">
                          <el-input v-model="driveCreate.first_name"></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Жолоочийн төрсөн огноо"
                          prop="birth_date"
                        >
                          <el-date-picker
                            v-model="driveCreate.birth_date"
                            type="date"
                            placeholder="огноо оруулах"
                            format="yyyy/MM/dd"
                            value-format="yyyy-MM-dd"
                          >
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="Жолоочийн имэйл хаяг" prop="email">
                          <el-input
                            type="email"
                            v-model="driveCreate.email"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Жолоочийн утас" prop="phone">
                          <el-input
                            type="number"
                            v-model="driveCreate.phone"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Жолоочийн нууц үг" prop="password">
                          <el-input
                            type="text"
                            v-model="driveCreate.password"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Банкны нэр">
                          <el-select
                            v-model="driveCreate.bank_code"
                            placeholder="Банкны нэр"
                          >
                            <el-option
                              v-for="(item, index) in banks"
                              :key="index"
                              :label="item.bank_name_mon"
                              :value="item.bank_code"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="Дансны нэр">
                          <el-input
                            placeholder="Карт нь дээрх нэр"
                            v-model="driveCreate.bank_account_name"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Дансны дугаар"
                          prop="bank_account_number"
                        >
                          <el-input
                            placeholder="Дансны дугаар"
                            type="number"
                            v-model="driveCreate.bank_account_number"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Ажил эрхлэлт">
                          <el-select
                            v-model="driveCreate.work_status"
                            placeholder="Ажил эрхлэлт"
                          >
                            <el-option
                              label="Сурагч"
                              value="student"
                            ></el-option>
                            <el-option
                              label="Оюутан"
                              value="uni_student"
                            ></el-option>
                            <el-option
                              label="Давхар ажил эрхлэдэг"
                              value="side_job"
                            ></el-option>
                            <el-option
                              label="Ток Ток-д бүтэн цагаар ажиллана"
                              value="full_time_toktok"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="Тээврийн хэрэгслийн төрөл">
                          <el-select
                            v-model="driveCreate.vehicle_type"
                            placeholder="Тээврийн хэрэгслийн төрөл"
                          >
                            <el-option label="Машин" value="car"></el-option>
                            <el-option label="Мопед" value="moped"></el-option>
                            <el-option
                              label="Дугуй"
                              value="bicycle"
                            ></el-option>
                            <el-option
                              label="Алхагч"
                              value="walker"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          label="Жолоочийн баг сонгоно уу"
                          prop="password"
                        >
                          <el-select
                            v-model="selectedTeam"
                            @change="calculateTeam"
                            placeholder="Жолоочийн баг сонго"
                          >
                            <el-option
                              v-for="(item, index) in allDrivers"
                              :key="index"
                              :label="item.group_name"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item prop="is_company_employee">
                          <p>Үндсэн ажилтан эсэх?</p>
                          <el-switch
                            v-model="driveDetail.is_company_employee"
                            border
                            :active-value="true"
                            :inactive-value="false"
                            name="slkdafj"
                          ></el-switch>
                        </el-form-item>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <router-link to="/driver-list">
                              <el-button size="medium" class="full-width"
                                >Буцах</el-button
                              >
                            </router-link>
                          </el-col>
                          <el-col :span="12">
                            <a>
                              <el-button
                                size="medium"
                                type="success"
                                @click="save"
                                >Хадгалах</el-button
                              >
                            </a>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
      </h3>
    </div>
    <el-dialog
      :title="this.typeBadge === 'create' ? 'Бадже үүсгэх' : 'Бадже шинэчлэх'"
      :visible.sync="visibleBadgeDialog"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-select v-model="badge" placeholder="Badge сонгох" value-key="key">
            <el-option
              v-for="(badge, indexBadge) in badgeTypes"
              :key="indexBadge"
              :label="badge.name"
              :value="badge"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="10">
          <el-date-picker
            v-model="owned_date"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="Өдөр сонгох"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="5" v-if="badge !== null">
          <el-image
            style="height: 40px; width: 40px"
            :src="badge.img_url"
          ></el-image>
        </el-col>
      </el-row>
      <el-row v-if="badge !== null">
        <p class="wrapNormal">{{ badge.purpose }}</p>
        <p class="wrapNormal">{{ badge.description }}</p>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancelCreateBadge">Болих</el-button>
        <el-button
          v-if="typeBadge === 'create'"
          size="mini"
          type="primary"
          @click="createBadge"
          >Үүсгэх</el-button
        >
        <el-button v-else size="mini" type="primary" @click="updateBadge"
          >Шинэчлэх</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
import axios from "axios";
import { getGroups } from "../utils/auth";
export default {
  created() {
    if (this.$route.name === "editDriver") {
      this.getDriveById(this.$route.query.id);
    } else if (this.$route.name === "addDriver") {
      this.getDriveById(this.$route.query.id);
    }
    this.getBadgeTypes();
    this.getDriverList();
  },
  mounted() {
    getGroups().then(data => {
      this.role = data;
    });
  },
  data() {
    return {
      badgeId: "",
      typeBadge: "create",
      loadingBadge: false,
      owned_date: "",
      badge: null,
      visibleBadgeDialog: false,
      driverBadges: [],
      badgeTypes: [],
      driveStatus: {
        user_uid: this.$route.query.id,
        status: ""
      },
      driveState: {
        user_uid: this.$route.query.id,
        status: 0
      },
      driveDetail: {},
      loading: true,
      driveCreate: {
        is_hr_verified: false,
        register_number: "",
        contract_number: "",
        email: "",
        emailVerified: true,
        is_company_employee: false,
        phone: 0,
        password: "",
        bank_account_number: "",
        bank_account_name: "",
        bank_code: "",
        full_name: "",
        disabled: false,
        last_name: "",
        first_name: "",
        birth_date: null,
        vehicle_type: null,
        work_status: null
      },
      allDrivers: [],
      banks: [],
      selectedTeam: [],
      rules: {
        register_number: [
          {
            required: true,
            message: "Та жолоочийн РД оруулна уу",
            trigger: "blur"
          },
          {
            min: 10,
            message: "Хамгийн багадаа 10 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        contract_number: [
          {
            required: true,
            message: "Та жолоочийн гэрээний дугаар оруулна уу",
            trigger: "blur"
          }
        ],
        full_name: [
          {
            required: true,
            message: "Та жолоочийн нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Та мэйл хаягаа оруулна уу",
            trigger: "blur"
          }
        ],
        phone: [
          {
            min: 8,
            message: "Хамгийн багадаа 8 оронтой байх ёстой",
            trigger: "blur"
          }
        ],
        password: [
          {
            min: 8,
            message: "Хамгийн багадаа 8 оронтой байх ёстой",
            trigger: "blur"
          }
        ],
        bank_account_name: [
          {
            required: true,
            message: "Та карт нь дээрх нэрээ оруулна уу",
            trigger: "blur"
          }
        ],
        bank_code: [
          {
            min: 8,
            message: "Банк заавал сонгоно уу?",
            trigger: "blur"
          }
        ],
        team: [
          {
            min: 8,
            message: "Баг заавал сонгоно уу?",
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Та жолоочийн овгийг оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        first_name: [
          {
            required: true,
            message: "Та жолоочийн нэр оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        birth_date: [
          {
            required: true,
            message: "Та жолоочийн төрсөн огноог оруулна уу",
            trigger: "blur"
          }
        ]
      },
      role: [],
      teamSendData: {
        driver_group_id: "",
        driver_group_name: ""
      }
    };
  },
  methods: {
    cancelCreateBadge() {
      this.badge = null;
      this.owned_date = "";
      this.visibleBadgeDialog = false;
      this.typeBadge = "create";
    },
    deleteBadge(id) {
      const body = {
        id: id
      };
      this.$confirm("Та устгахдаа итгэлтэй байна уу?", "Устгах", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      }).then(() => {
        axios
          .post(
            process.env.VUE_APP_DRIVER_API + "admin/driver/deleteBadge",
            body
          )
          .then(response => {
            if (response.data.status === "success") {
              this.getDriveById(this.$route.query.id);
            } else {
              this.$notify({
                title: "Алдаа гарлаа",
                message: "Badge устгаж чадсангүй",
                type: "warning"
              });
            }
          });
        this.$message({
          type: "success",
          message: "Амжилттай устгалаа"
        });
      });
    },
    createBadge() {
      this.loadingBadge = true;
      const body = {
        badge_id: this.badge.id,
        badge_key: this.badge.key,
        driver_uid: this.$route.query.id,
        owned_date: this.owned_date
      };
      axios
        .post(process.env.VUE_APP_DRIVER_API + "admin/driver/createBadge", body)
        .then(response => {
          if (response.data.status === "success") {
            this.visibleBadgeDialog = false;
            this.$notify({
              title: "Амжилттай",
              message: "Badge амжилттай нэмэгдлээ",
              type: "success"
            });
            this.badge = null;
            this.owned_date = "";
            this.getDriveById(this.$route.query.id);
            this.loadingBadge = false;
          } else {
            this.loadingBadge = false;
            this.$notify({
              title: "Алдаа гарлаа",
              message: "Badge үүсгэж чадсангүй",
              type: "warning"
            });
          }
        });
    },
    updateBadge() {
      this.loadingBadge = true;
      const body = {
        id: this.badgeId,
        badge_id: this.badge.id,
        badge_key: this.badge.key,
        driver_uid: this.$route.query.id,
        owned_date: this.owned_date
      };
      axios
        .post(process.env.VUE_APP_DRIVER_API + "admin/driver/updateBadge", body)
        .then(response => {
          if (response.data.status === "success") {
            this.visibleBadgeDialog = false;
            this.$notify({
              title: "Амжилттай",
              message: "Badge амжилттай шинэчлэгдлээ",
              type: "success"
            });
            this.badge = null;
            this.owned_date = "";
            this.typeBadge = "create";
            this.getDriveById(this.$route.query.id);
            this.loadingBadge = false;
          } else {
            this.loadingBadge = false;
            this.$notify({
              title: "Алдаа гарлаа",
              message: "Badge шинэчилж чадсангүй",
              type: "warning"
            });
          }
        });
    },
    editBadge(badge, type) {
      this.typeBadge = type;
      let tempData = [];
      tempData = this.badgeTypes.filter(
        element => element.id === badge.badge_id
      );
      this.badge = tempData[0];
      this.owned_date = badge.owned_date;
      this.badgeId = badge.id;
      this.visibleBadgeDialog = true;
    },
    getBadgeTypes() {
      axios
        .get(process.env.VUE_APP_DRIVER_API + "admin/driver/getBadgeTypes")
        .then(response => {
          if (response.data.status === "success") {
            this.badgeTypes = response.data.data;
          } else {
            this.badgeTypes = [];
          }
        });
    },
    calculateTeam() {
      this.allDrivers.forEach(element1 => {
        if (this.selectedTeam === element1.id) {
          this.teamSendData.driver_group_id = element1.id;
          this.teamSendData.driver_group_name = element1.group_name;
        }
      });
    },
    getDriverList() {
      this.allDrivers = [];
      service
        .getDriverTeamList()
        .then(driverRes => {
          if (driverRes.status == "success") {
            for (let i = 0; i < driverRes.data.length; i++) {
              this.allDrivers.push(driverRes.data[i]);
            }
          } else {
            this.$notify({
              title: "Алдаа",
              message: driverRes.error.message,
              type: "warning"
            });
          }
        })
        .catch(error => {
          this.$notify.error({
            title: "Уучлаарай",
            position: "bottom-left",
            message: error.message,
            duration: 2000
          });
        });
    },
    getDriveById(id) {
      let self = this;
      self.loadingBadge = true;
      try {
        // service.getDriveById(id).then(response => {
        axios
          .post(
            process.env.VUE_APP_DRIVER_API + "admin/driver/getDriverByUid",
            { user_uid: id }
          )
          .then(driverRes => {
            if (driverRes.data.status == "success") {
              self.driveDetail = driverRes.data.response;
              self.driveStatus.status = driverRes.data.response.status;
              self.driveState.status = driverRes.data.response.is_disabled;
              self.teamSendData.driver_group_id =
                driverRes.data.response.driver_group_name;
              self.teamSendData.driver_group_name =
                driverRes.data.response.driver_group_name;
              self.selectedTeam = driverRes.data.response.driver_group_name;
              self.driverBadges = driverRes.data.response.DriverBadges;
              self.loading = false;
              self.loadingBadge = false;
            }
            axios
              .get(process.env.VUE_APP_DRIVER_API + "admin/driver/getBankCodes")
              .then(axiosRes => {
                if (axiosRes.data.status == "success") {
                  self.banks = axiosRes.data.response;
                } else {
                  self.$notify({
                    title: "Амжилтгүй",
                    message: axiosRes.error,
                    type: "warning"
                  });
                }
              })
              .catch(error => {
                self.$notify.error({
                  title: "Уучлаарай",
                  position: "bottom-left",
                  message: error.message,
                  duration: 2000
                });
              });
          });
      } catch (error) {
        this.$notify.error({
          title: "Уучлаарай",
          position: "bottom-left",
          message: error.message,
          duration: 2000
        });
      }
    },
    getDriveStatus(value) {
      this.driveStatus.status = value;
      service
        .updateDriverStatus(this.driveStatus)
        .then(response => {
          if (response.status == "success") {
            this.$notify({
              title: "Амжилттай",
              message: "Жолооч статус өөрчлөгдлөө",
              type: "success"
            });
          } else {
            this.$notify({
              title: "Жолоочийн статус өөрчлөхөд алдаа гарлаа",
              message: response.error,
              type: "warning"
            });
          }
        })
        .catch(error => {
          this.$notify.error({
            title: "Уучлаарай",
            position: "bottom-left",
            message: error.message,
            duration: 2000
          });
        });
    },
    changeDriveState() {
      this.$confirm(
        "Та жолоочийн эрхийг өөрчлөхдөө итгэлтэй байна уу?",
        "Анхаар",
        {
          confirmButtonText: "Тийм",
          cancelButtonText: "Буцах",
          type: "warning"
        }
      )
        .catch(error => {
          this.$notify.error({
            title: "Уучлаарай",
            position: "bottom-left",
            message: error.message,
            duration: 2000
          });
        })
        .then(() => {
          service.manageDriverAccount(this.driveState).then(response => {
            if (response.status == "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Жолоочийн эрх өөрчлөгдлөө",
                type: "success"
              });
              this.getDriveById(this.$route.query.id);
            } else {
              this.$notify({
                title: "Жолоочийн эрхийг өөрчлөхөд алдаа гарлаа",
                message: response.error,
                type: "warning"
              });
            }
          });
        });
    },
    save() {
      this.$refs.driveCreate.validate(valid => {
        if (valid) {
          if (this.$route.name === "editDriver") {
            this.driveDetail.driver_group_id = this.teamSendData.driver_group_id;
            this.driveDetail.driver_group_name = this.teamSendData.driver_group_name;
            this.driveDetail.user_uid = this.$route.query.id;
            axios
              .post(
                process.env.VUE_APP_DRIVER_API + "admin/driver/updateUserByUid",
                this.driveDetail
              )
              .catch(error => {
                this.$notify.error({
                  title: "Уучлаарай",
                  position: "bottom-left",
                  message: error.message,
                  duration: 2000
                });
              })
              .then(response => {
                if (response.data.status === "success") {
                  this.$notify({
                    title: "Амжилттай",
                    message: "Жолоочийн мэдээлэл засагдлаа",
                    type: "success"
                  });
                  this.$router.go(-1);
                } else if (response.data.status === "unsuccess") {
                  this.$notify({
                    title: "Амжилтгүй",
                    message:
                      "Жолоочийн мэдээлэл засахад алдаа гарлаа " +
                      " " +
                      response.data.error.message,
                    type: "warning"
                  });
                }
              });
          } else if (this.$route.name === "addDriver") {
            this.driveCreate.phone = this.driveCreate.phone;
            this.driveCreate.driver_group_id = this.teamSendData.driver_group_id;
            this.driveCreate.driver_group_name = this.teamSendData.driver_group_name;
            axios
              .post(
                process.env.VUE_APP_DRIVER_API + "admin/driver/createDriver",
                this.driveCreate
              )
              .then(response => {
                if (response.data.status === "success") {
                  this.$notify({
                    title: "Амжилттай",
                    message: "Жолооч нэмэгдлээ",
                    type: "success"
                  });
                  this.$router.go(-1);
                } else if (response.data.status === "unsuccess") {
                  this.$notify({
                    title: "Амжилтгүй",
                    message:
                      "Жолооч нэмэхэд алдаа гарлаа" +
                      " " +
                      response.data.error.message,
                    type: "warning"
                  });
                }
              });

            service.addDrive(this.driveCreate).then(response => {
              if (response.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Жолооч нэмэгдлээ",
                  type: "success"
                });
                this.$router.go(-1);
              } else if (response.status === "unsuccess") {
                this.$notify({
                  title: "Амжилтгүй",
                  message:
                    "Жолооч нэмэхэд алдаа гарлаа" +
                    " " +
                    response.error.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
p.wrapNormal {
  word-break: break-word;
  white-space: normal;
  text-align: justify;
}
</style>
